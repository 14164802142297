.wrapper{ 
  background-color: #070B0D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%235681FF'/%3E%3Cstop offset='1' stop-color='%23FFCC1E'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(133,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%236FFFA6'/%3E%3Cstop offset='1' stop-color='%23FF73A2'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='6.27'%3E%3Cpath transform='translate(-131.95 7.200000000000003) rotate(-1.6499999999999986 1409 581) scale(0.928743)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='2.09' transform='translate(-161.5 100) rotate(9.5 800 450) scale(1.0094)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(24.400000000000002 -115.5) rotate(74.5 401 736) scale(1.0094)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='1.9'%3E%3Cpath transform='translate(600 1) rotate(-0.25 150 345) scale(0.9795809999999999)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='4.18' transform='translate(-174.5 -270.5) rotate(64.80000000000001 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-370.4 101.6) rotate(10.799999999999997 1400 132) scale(0.795)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}

.About_section{
  padding-top: 100px;
}
/* .About_card{
background: linear-gradient(106.64deg, rgba(123, 114, 157, 0.13) -9.59%, rgba(202, 233, 183, 0) 161.49%);
border: 2px solid rgba(255, 255, 255, 0.16);
backdrop-filter: blur(4px);
border-radius: 42px;
margin-top: 80px;
padding: 30px 50px;
color: #d0dbdd;

} */

.About_card{
  background: linear-gradient(106.64deg, rgb(0 0 0 / 13%) -9.59%, rgba(202, 233, 183, 0) 161.49%);
  border: 2px solid rgb(0 255 220 / 16%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 42px;
  margin-top: 80px;
  padding: 30px 50px;
  color: #fefffff5;

}

.Profile_container{
  margin-top: 26px;
}
h1{
  font-size: 28px;
  line-height: 39px;
}
.Name{
  font-size: 21px;
  font-weight: 400;
}
.Name span{
  font-size: 28px;
  font-weight: 500;
}
h2{
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
/* responsive */
@media(max-width: 767px){
  .About_section {
    padding-top: 0;
}

.Name{
  font-size: 18px;
  font-weight: 400;
}
.Name span{
  font-size: 21px;
  font-weight: 500;
}
h2{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
}