body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background-color: #070B0D;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
p{
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
