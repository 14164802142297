.section_wrapper {
    margin-top: 90px;
 
}

.heading_chip { 
    background: linear-gradient(106.64deg, rgba(123, 114, 157, 0.13) -9.59%, rgba(202, 233, 183, 0) 161.49%);
    border: 2px solid rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(4px);
    border-radius: 42px;
    color: #a8dbe7;
    padding: 10px 60px;
    font-size: 21px;
    font-weight: 600;
    
}
.skill-content .row{
    margin-top: 70px;
}
.card-row{
    margin-top: 40px;
    margin-bottom: 96px;
    margin-left: 6px;
    margin-right: 6px;
}
.card{
background: linear-gradient(106.64deg, rgb(44 44 44 / 13%) -9.59%, rgb(128 145 117 / 0%) 161.49%);
border: 1px solid rgba(255, 255, 255, 0.16);
backdrop-filter: blur(4px);
border-radius: 42px;
max-width:412px;
padding: 24px;
color: #ffffff;
cursor: pointer;
}

/* .card_2{
position: absolute;
top: 60px;
background: linear-gradient(106.64deg, rgba(123, 114, 157, 0.13) -9.59%, rgba(202, 233, 183, 0) 161.49%);
border: 2px solid rgba(255, 255, 255, 0.16);
backdrop-filter: blur(4px);
border-radius: 42px;
width: 370px;
height: 279px;
max-width:412px;
color: #ffff;
padding: 12px 21px;
} */

.skill-wrapper{
    padding: 14px;
}
.skill-chip{
    background: linear-gradient(106.64deg, rgba(123, 114, 157, 0.13) -9.59%, rgba(202, 233, 183, 0) 161.49%);
    /* border: 2px solid rgba(255, 255, 255, 0.16); */
    backdrop-filter: blur(4px);
    border-radius: 42px; 
    display: inline-block;
    padding: 8px 18px;
    vertical-align: middle;
    margin: 8px 4px;
    transition: all 250ms;
}
.skill-chip:hover{
    /* background-color: rgb(15, 23, 36); */
    background-color: #eaebeb33;
    transform: translateY(-10px);
}
.skill-inner{
    display: flex;
    align-items: center;
    justify-content: center;
}
.skill-inner >img{
    margin: 0 2px;
}