.navbar{
    margin-top: 30px;
    background: linear-gradient(90.39deg, rgb(193 184 184 / 49%) -1.14%, rgb(205 205 205 / 14%) -1.13%, rgb(240 255 244 / 4%) 99.99%);
  border-radius: 24px;
  backdrop-filter: blur(30px);
  justify-content: space-between;
  
  }
  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    z-index: 10;
    margin-top: 0;
  }
  .navbar-brand{
    padding: 8px 14px;
  }
  .navbar-nav .nav-item{
    margin-left: 60px;
    margin-right: 10px;
  }
  .nav-item a{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .navbar-toggler:focus , .active{
    outline: none;
  }
  .navbar-toggler-icon{
    z-index: 1000;
  }
  .active a{
   color: #1EB63F;
  }
  .nav-item a:hover{
    color: #1EB63F;
  }

  .logo{
    font-weight: 700;
font-size: 24px;
line-height: 36px;
color: #FFFFFF;
  }
  .dev{
    color:#1EB63F;
  }